@tailwind base;
@tailwind components;
@tailwind utilities;

@layer {
  html,
  body {
    height: 100%;
    background-color: #f1f5f9;
  }
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #18181b;
  /* border-radius: 0 0.75rem 0.75rem 0; */
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #52525b;
  border-radius: 0.75rem;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #71717a;
}

/* @media screen and (max-width: 768px) {
  ::-webkit-scrollbar {
    display: none;
  }
} */
